<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
      ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="mx-auto font-18">
        Tanya Pasjob
      </h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <!-- Experience List-->
    <div class="page-content mt-4 mx-3">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <select
              class="form-select vc  mb-3"
              aria-label="Default select example"
              v-model="payload.user_type"
              v-if="this.userData.userType"
              disabled
            >
              <option
                v-for="option in this.userRole"
                :key="option.id"
                :value="option.id"
              >{{ option.label }}</option>
            </select>
            <select
              class="form-select form-select-sm mb-3"
              aria-label="Default select example"
              v-model="payload.user_type"
              v-else
            >
              <option
                v-for="option in this.userRole"
                :key="option.id"
                :value="option.id"
              >{{ option.label }}</option>
            </select>
            <input
              type="email"
              class="form-control   mb-3"
              placeholder="Email Anda ..."
              v-if="this.userData.email"
              v-model="payload.email"
              disabled
            >
            <input
              type="email"
              class="form-control  mb-3"
              placeholder="Email Anda ..."
              v-model="payload.email"
              v-else
            >
            <textarea
              class="form-control form-control-sm  "
              placeholder="Report / Kendala"
              v-model="payload.report"
            ></textarea>

            <hr>

            <div class="d-flex">
              <button type="submit" class="btn bg-highlight w-100">Kirim Pesan</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- End Experience List-->

    <!-- Main Menu-->
    <div
      id="menu-main"
      class="menu menu-box-left rounded-0"
      data-menu-width="280"
      data-menu-active="nav-welcome"
    >
      <MenuMain @close="menuClose" />
    </div>

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <!-- Colors Menu-->
    <div
      id="menu-colors"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-colors.html"
      data-menu-height="480"
    >
      <MenuColors @close="menuClose" />
    </div>

    <!-- modals redeem failed -->
    <div
      id="error-submit-form"
      class="menu menu-box-modal rounded-m p-2"
      data-menu-height="200"
      data-menu-width="350"
      style="display: block; width: 350px;"
    >
      <div class="menu-title d-flex align-items-center">
        <i
          class="
            fa fa-exclamation-triangle
            scale-box
            float-start
            mx-3
            fa-3x
            mt-1
            color-red-dark
          "
        ></i>
        <h1 class="font-20">Gagal</h1>
        <a href="#" @click="menuClose" class="close-menu"
        ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="content my-4 d-flex justify-content-center">
        {{ errors[0] }}
      </div>
      <div class="content d-flex justify-content-center">
        <button @click="menuClose" class="btn bg-highlight">Oke</button>
      </div>
    </div>
    <!-- End modals redeem failed -->

    <div @click="menuClose" class="menu-hider"></div>

    <div
      id="success-report-question"
      class="menu menu-box-modal rounded-m p-2"
      data-menu-height="200"
      data-menu-width="350"
      style="display: block; width: 350px;"
    >
      <div class="menu-title d-flex align-items-center">
        <i
          class="
            fa fa-check-circle
            scale-box
            float-start
            mx-3
            fa-3x
            mt-1
            color-highlight
          "
        ></i>
        <h1 class="font-20">Terkirim</h1>
        <a href="#" @click="menuClose" class="close-menu"
        ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="content my-4 d-flex justify-content-center">
        Pertanyaan Terkirim
      </div>
      <div class="content d-flex justify-content-center">
        <button @click="menuClose" class="btn bg-highlight">Oke</button>
      </div>
    </div>

    <div
      id="error-report-question"
      class="menu menu-box-modal rounded-m p-2"
      data-menu-height="200"
      data-menu-width="350"
      style="display: block; width: 350px;"
    >
      <div class="menu-title d-flex align-items-center">
        <i
          class="
            fa fa-exclamation-triangle
            scale-box
            float-start
            mx-3
            fa-3x
            mt-1
            color-red-dark
          "
        ></i>
        <h1 class="font-20">Gagal</h1>
        <a href="#" @click="menuClose" class="close-menu"
        ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="content my-4 d-flex justify-content-center">
        <ul>
          <li v-for="error in this.errors">{{error}}</li>
        </ul>
      </div>
      <div class="content d-flex justify-content-center">
        <button @click="menuClose" class="btn bg-highlight">Oke</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import { Toast } from "bootstrap";
import useClipboard from "vue-clipboard3";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";

import MenuMain from "@/components/menu/MenuMain";
import MenuColors from "@/components/menu/MenuColors";

export default {
  name: "Tanya Pasjob",
  components: {
    Header,
    Footer,
    MenuMain,
    MenuColors,
    EmptyState,
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      userData: {},
      userRole: [
        {id: '', label: 'Bertanya sebagai ...'},
        {id: 'J', label: 'Job Seeker'},
        {id: 'E', label: 'Pembisnis'},
      ],
      isLoading: true,
      errors: [],
      options: [],
      payload: {
        user_type: '',
        email: '',
        report: ''
      }
    };
  },
  watch: {
  },
  mounted() {
    init_template();
    this.isLoading = false;
    if (localStorage.account) {
      this.userData = JSON.parse(localStorage.account)
      this.payload.email = this.userData.email

      switch (this.userData.userType) {
        case 'J':
          this.payload.user_type = 'J'
          break;

        case 'E':
          this.payload.user_type = 'E'
          break;
      }
    }
  },
  methods: {
    menuOpen,
    menuClose,
    async copyLink() {
      const { toClipboard } = useClipboard();

      try {
        await toClipboard(this.inviteLink);
        var notificationToast = document.getElementById("toast-2");
        notificationToast = new Toast(notificationToast);
        notificationToast.show();
        console.log("url : ", axios.defaults.baseURL + this.$route.fullPath);
      } catch (e) {
        console.error(e);
      }
    },
    async clearForm() {
      if (localStorage.account) {
        this.userData = JSON.parse(localStorage.account)
        this.payload.email = this.userData.email

        switch (this.userData.userType) {
          case 'J':
            this.payload.user_type = 'J'
            break;

          case 'E':
            this.payload.user_type = 'E'
            break;
        }
      } else {
        this.payload.user_type = ''
        this.payload.email = ''
      }
      this.payload.report = ''
    },
    async submitForm() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem["token"];
      this.errors = [];

      let validationStatus = true

      if (this.payload.user_type === '') {
        validationStatus = false
        this.errors = [
          'Role belum dipilih'
        ]
        this.menuOpen("error-submit-form");
      }

      if (this.payload.email === '' && validationStatus === true) {
        validationStatus = false
        this.errors = [
          'Silahkan input email anda'
        ]
        this.menuOpen("error-submit-form");
      }

      if (this.payload.report === '' && validationStatus === true) {
        validationStatus = false
        this.errors = [
          'Silahkan input kendala anda'
        ]
        this.menuOpen("error-submit-form");
      }

      if (validationStatus === true) {
        console.log(this.payload);

        await axios
          .post("/user-report/post-question", this.payload, {
            baseURL: this.apiEndpoint,
          })
          .then((response) => {
            this.menuOpen("success-report-question");
            this.clearForm();
          })
          .catch((error) => {
            this.menuOpen("error-report-question");
            console.log(error.response);
          });
      }
    },
  },
};
</script>

<style>
.search-box .fa-copy {
  position: absolute;
  left: 0px;
  line-height: 52px;
  padding: 0px 15px 0px 30px;
}
</style>

